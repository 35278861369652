export const LOGIN_ACTION = 'LOGIN_ACTION';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const SET_FIRST_PASSWORD_ACTION = 'SET_FIRST_PASSWORD_ACTION';
export const SET_FIRST_PASSWORD_SUCCESS = 'SET_FIRST_PASSWORD_SUCCESS';
export const SET_FIRST_PASSWORD_FAIL = 'SET_FIRST_PASSWORD_FAIL';
export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD_ACTION';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const GET_VERIFICATION_CODE_ACTION = 'GET_VERIFICATION_CODE_ACTION';
export const GET_VERIFICATION_CODE_SUCCESS = 'GET_VERIFICATION_CODE_SUCCESS';
export const GET_VERIFICATION_CODE_FAIL = 'GET_VERIFICATION_CODE_FAIL';
export const GET_PROFILE_ACTION = 'GET_PROFILE_ACTION';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const loginAction = () => ({
    type: LOGIN_ACTION,
});

export const loginSucceeded = response => ({
    type: LOGIN_SUCCESS,
    response,
});

export const loginFailed = error => ({
    type: LOGIN_FAIL,
    error,
});

export const logoutAction = () => ({
    type: LOGOUT_ACTION,
});

export const logoutSucceeded = response => ({
    type: LOGOUT_SUCCESS,
    response,
});

export const logoutFailed = error => ({
    type: LOGOUT_FAIL,
    error,
});

export const setFirstPasswordAction = () => ({
    type: SET_FIRST_PASSWORD_ACTION,
});

export const setFirstPasswordSucceeded = response => ({
    type: SET_FIRST_PASSWORD_SUCCESS,
    response,
});

export const setFirstPasswordFailed = error => ({
    type: SET_FIRST_PASSWORD_FAIL,
    error,
});

export const resetPasswordAction = () => ({
    type: RESET_PASSWORD_ACTION,
});

export const resetPasswordSucceeded = response => ({
    type: RESET_PASSWORD_SUCCESS,
    response,
});

export const resetPasswordFailed = error => ({
    type: RESET_PASSWORD_FAIL,
    error,
});

export const postVerificationCodeAction = () => ({
    type: GET_VERIFICATION_CODE_ACTION,
});

export const postVerificationCodeSucceeded = response => ({
    type: GET_VERIFICATION_CODE_SUCCESS,
    response,
});

export const postVerificationCodeFailed = error => ({
    type: GET_VERIFICATION_CODE_FAIL,
    error,
});

export const getProfileAction = () => ({
    type: GET_PROFILE_ACTION,
});

export const getProfileSuccess = response => ({
    type: GET_PROFILE_SUCCESS,
    response,
});

export const getProfileFail = error => ({
    type: GET_PROFILE_FAIL,
    error,
});
