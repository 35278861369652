/**
 * Created by oleg on 12/26/16.
 */
import submitRegistration from './registration';

export { NEXT_STEP, PREV_STEP } from './navigationControl';
export { REGISTRATION_SUBMIT, REGISTRATION_ERROR, REGISTRATION_SUCCESS } from './registrationControl';

export { submitRegistration };
export { nextPage, previousPage } from './navigation';
