// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amplify-field .amplify-field-group {
  width: 100%;
}
.amplify-field .amplify-label {
  display: table-cell;
  white-space: nowrap;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 2px;
}
[data-amplify-authenticator-signin] > div:first-child > fieldset,
[data-amplify-authenticator-signin] > div:first-child > button {
  display: none;
}
[data-amplify-authenticator-forgotpassword] > fieldset > div:first-child + div,
[data-amplify-authenticator-forgotpassword] > fieldset .amplify-button.amplify-button--primary {
  display: none;
}
[data-amplify-authenticator-confirmresetpassword] > fieldset > div:first-child + div,
[data-amplify-authenticator-confirmresetpassword] > fieldset .amplify-button.amplify-button--primary {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/containers/auth/style.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;AAAJ;AAFA;EAKI,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;AAAJ;AAIA;;EAEI,aAAA;AAFJ;AAMA;;EAGI,aAAA;AALJ;AASA;;EAGI,aAAA;AARJ","sourcesContent":[".amplify-field {\n  .amplify-field-group {\n    width: 100%;\n  }\n  .amplify-label {\n    display: table-cell;\n    white-space: nowrap;\n    vertical-align: middle;\n    padding: 6px 12px;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 1;\n    color: #555;\n    text-align: center;\n    background-color: #eee;\n    border: 1px solid #ccc;\n    border-radius: 2px;\n  }\n}\n\n[data-amplify-authenticator-signin] > div:first-child {\n  > fieldset, > button {\n    display: none;\n  }\n}\n\n[data-amplify-authenticator-forgotpassword] > fieldset {\n  > div:first-child + div,\n  .amplify-button.amplify-button--primary {\n    display: none;\n  }\n}\n\n[data-amplify-authenticator-confirmresetpassword] > fieldset {\n  > div:first-child + div,\n  .amplify-button.amplify-button--primary {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
