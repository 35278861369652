import React from 'react';
import { Button } from 'antd';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import {
    composeValidators,
    email,
    maxLength,
    required,
} from '@src/utils/helpers/validationRules';
import { useAuth } from './AuthContext';

const focusOnError = createDecorator();

const AuthSignInForm = () => {
    const { submitForm, isPending } = useAuth();

    const onSubmitHandler = values => {
        const username = String(values.username).toLowerCase().trim();
        const password = String(values.password).trim();

        return submitForm({ username, password });
    };

    return (
        <Form
            onSubmit={onSubmitHandler}
            decorators={[focusOnError]}
        >
            {
                params => (
                    <form onSubmit={params.handleSubmit} id="login-form">
                        <Field
                            name="username"
                            component={InputGroupAntInputAdapter}
                            controlId="username"
                            labelText="Username"
                            isRequired
                            validate={composeValidators(required, maxLength(180), email)}
                        />
                        <Field
                            name="password"
                            component={InputGroupAntInputAdapter}
                            controlId="password"
                            labelText="Password"
                            isRequired
                            validate={composeValidators(required, maxLength(180))}
                            type="password"
                        />
                        <Button
                            className="right-buffer-xs"
                            htmlType="submit"
                            type="primary"
                            loading={isPending}
                            id="login-form--submit-button"
                            block
                        >
                            Sign in
                        </Button>
                    </form>
                )
            }
        </Form>
    );
};

export default AuthSignInForm;
