import { useEffect, useState, useCallback } from 'react';

const useWindowInFocus = ({
    onFocus,
    onBlur,
} = {}) => {
    const [isInFocus, setIsInFocus] = useState(true);

    const onBlurHandler = useCallback(event => {
        onBlur?.(event);
        setIsInFocus(false);
    }, [onBlur]);

    const onFocusHandler = useCallback(event => {
        onFocus?.(event);
        setIsInFocus(true);
    }, [onFocus]);

    useEffect(() => {
        window.addEventListener('focus', onFocusHandler);
        window.addEventListener('blur', onBlurHandler);

        return () => {
            window.removeEventListener('focus', onFocusHandler);
            window.removeEventListener('blur', onBlurHandler);
        };
    }, []);

    return isInFocus;
};

export default useWindowInFocus;
