import React from 'react';
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import {
    FAQS,
    CONTACT_INFO,
} from '@src/utils/constants/routerConstants';

const SCTInfoMenu = ({
    mode = 'horizontal',
}) => (
    <Menu mode={mode}>
        <SubMenu key="SubMenu3" title="Help">
            <MenuItem key="setting10">
                <a href={FAQS} target="blank">FAQ's</a>
            </MenuItem>
            <MenuItem key="setting11">
                <a href={CONTACT_INFO} target="blank">Contact Us</a>
            </MenuItem>
        </SubMenu>
    </Menu>
);

export default SCTInfoMenu;
