export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = 'FETCH_CURRENT_USER_FAIL';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';

export const UPDATE_USER_ACTION = 'UPDATE_USER_ACTION';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const UPDATE_USER_ACTIVE_STATE_ACTION = 'UPDATE_USER_ACTIVE_STATE_ACTION';
export const UPDATE_USER_ACTIVE_STATE_SUCCESS = 'UPDATE_USER_ACTIVE_STATE_SUCCESS';
export const UPDATE_USER_ACTIVE_STATE_FAIL = 'UPDATE_USER_ACTIVE_STATE_FAIL';

export const UPDATE_USER_ADMIN_STATE_ACTION = 'UPDATE_USER_ADMIN_STATE_ACTION';
export const UPDATE_USER_ADMIN_STATE_SUCCESS = 'UPDATE_USER_ADMIN_STATE_SUCCESS';
export const UPDATE_USER_ADMIN_STATE_FAIL = 'UPDATE_USER_ADMIN_STATE_FAIL';

export const EDIT_USER_ACTION = 'EDIT_USER_ACTION';
export const EDIT_USER_SUBMIT = 'EDIT_USER_SUBMIT';
export const EDIT_USER_CANCEL = 'EDIT_USER_CANCEL';

export const CREATE_USER_ACTION = 'CREATE_USER_ACTION';
export const CREATE_USER_SUBMIT = 'CREATE_USER_SUBMIT';
export const CREATE_USER_CANCEL = 'CREATE_USER_CANCEL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const RESEND_CONFIRMATION_CODE_ACTION = 'RESEND_CONFIRMATION_CODE_ACTION';
export const RESEND_CONFIRMATION_CODE_SUCCESS = 'RESEND_CONFIRMATION_CODE_SUCCESS';
export const RESEND_CONFIRMATION_CODE_FAIL = 'RESEND_CONFIRMATION_CODE_FAIL';

export const createUserAction = () => ({
    type: CREATE_USER_ACTION,
});

export const createUserSubmit = () => ({
    type: CREATE_USER_SUBMIT,
});

export const createUserCancel = () => ({
    type: CREATE_USER_CANCEL,
});

export const createUserSucceeded = response => ({
    type: CREATE_USER_SUCCESS,
    response,
});

export const createUserFailed = error => ({
    type: CREATE_USER_FAIL,
    error,
});

export const fetchUsers = () => ({
    type: FETCH_USERS,
});

export const fetchUsersSucceeded = response => ({
    type: FETCH_USERS_SUCCESS,
    response,
});

export const fetchUsersFailed = error => ({
    type: FETCH_USERS_FAIL,
    error,
});

export const fetchCurrentUser = () => ({
    type: FETCH_CURRENT_USER,
});

export const fetchCurrentUserSucceeded = response => ({
    type: FETCH_CURRENT_USER_SUCCESS,
    response,
});

export const fetchCurrentUserFailed = error => ({
    type: FETCH_CURRENT_USER_FAIL,
    payload: { error },
});

export const resetCurrentUser = () => ({
    type: RESET_CURRENT_USER,
});

export const updateUserAction = username => ({
    type: UPDATE_USER_ACTION,
    username,
});

export const updateUserSucceeded = (response, username) => ({
    type: UPDATE_USER_SUCCESS,
    response,
    username,
});

export const updateUserFailed = (error, username) => ({
    type: UPDATE_USER_FAIL,
    error,
    username,
});

export const updateUserActiveStateAction = username => ({
    type: UPDATE_USER_ACTIVE_STATE_ACTION,
    username,
});

export const updateUserActiveStateSucceeded = (response, username) => ({
    type: UPDATE_USER_ACTIVE_STATE_SUCCESS,
    response,
    username,
});

export const updateUserActiveStateFailed = (error, username) => ({
    type: UPDATE_USER_ACTIVE_STATE_FAIL,
    error,
    username,
});

export const updateUserAdminStateAction = username => ({
    type: UPDATE_USER_ADMIN_STATE_ACTION,
    username,
});

export const updateUserAdminStateSucceeded = (response, username) => ({
    type: UPDATE_USER_ADMIN_STATE_SUCCESS,
    response,
    username,
});

export const updateUserAdminStateFailed = (error, username) => ({
    type: UPDATE_USER_ADMIN_STATE_FAIL,
    error,
    username,
});

export const editUserAction = user => ({
    type: EDIT_USER_ACTION,
    user,
});

export const editUserSubmit = user => ({
    type: EDIT_USER_SUBMIT,
    user,
});

export const editUserCancel = () => ({
    type: EDIT_USER_CANCEL,
});

export const resendConfirmationCodeAction = username => ({
    type: RESEND_CONFIRMATION_CODE_ACTION,
    username,
});

export const resendConfirmationCodeSucceeded = (response, username) => ({
    type: RESEND_CONFIRMATION_CODE_SUCCESS,
    response,
    username,
});

export const resendConfirmationCodeFailed = (error, username) => ({
    type: RESEND_CONFIRMATION_CODE_FAIL,
    error,
    username,
});
