import React from 'react';
import Footer from '@src/components/Footer';
import UnauthorizedNavigation from '@src/containers/auth/UnauthorizedNavigation';

const UnauthorizedLayout = ({ children }) => (
    <div id="mainContainer" className={`bg-container background-${Math.floor(Math.random() * 10) + 1}`}>
        <header id="header">
            <UnauthorizedNavigation />
        </header>
        <main
            className="container overlayedZone"
            role="main"
            id="main"
        >
            {children}
        </main>
        <footer>
            <Footer />
        </footer>
    </div>
);

export default UnauthorizedLayout;
