import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import AuthBoundaries from './AuthBoundaries';
import { AuthProvider } from './AuthContext';
import AuthRouter from './AuthRouter';
import CustomTokenStorage from './CustomTokenStorage';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import './style.less';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.USER_POOL_ID,
            userPoolClientId: process.env.USER_POOL_CLIENT_ID,
        },
    },
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CustomTokenStorage());

const Auth = ({ children }) => (
    <Authenticator.Provider>
        <AuthProvider>
            <AuthBoundaries authPage={<AuthRouter />}>
                {children}
            </AuthBoundaries>
        </AuthProvider>
    </Authenticator.Provider>
);

export default Auth;
