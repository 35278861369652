import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '@src/components/Loader';
import BlockedAccountLayout from '@src/layouts/BlockedAccountLayout';
import { getIsProfileFetching, getProfileError } from '@src/store/reducers/authorization';

const CreditHoldBoundary = ({ children }) => {
    const isProfileFetching = useSelector(getIsProfileFetching);
    const profileError = useSelector(getProfileError);

    if (isProfileFetching) {
        return (
            <Loader show />
        );
    }

    if (profileError) {
        return (
            <BlockedAccountLayout>
                <div className="error-section top-buffer-lg">
                    {profileError}
                </div>
            </BlockedAccountLayout>
        );
    }

    return children;
};

export default CreditHoldBoundary;
