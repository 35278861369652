import {
    fetchAllUsers,
    fetchCurrentUser as fetchCurrentUserApi,
} from '@src/utils/apiServices/userRequests';
import {
    fetchUsers, fetchUsersFailed, fetchUsersSucceeded,
    fetchCurrentUser, fetchCurrentUserSucceeded, fetchCurrentUserFailed,
} from './userMaintenanceControl';

export const getUsers = () => dispatch => {
    dispatch(fetchUsers());

    return fetchAllUsers().then(response => {
        dispatch(fetchUsersSucceeded(response));
    }).catch(error => (
        dispatch(fetchUsersFailed(error))
    ));
};

export const getCurrentUser = () => dispatch => {
    dispatch(fetchCurrentUser());

    return fetchCurrentUserApi().then(response => {
        dispatch(fetchCurrentUserSucceeded(response));
    }).catch(error => {
        const errorMessage = error.response?.data || 'Something went wrong while fetching current user.';
        dispatch(fetchCurrentUserFailed(errorMessage));
    });
};
