import users, { getUsers } from './users';
import userOnEdit, { getEditUserId } from './userOnEdit';
import userUpdate from './userUpdate';
import username from './username';
import deactivateOption from './deactivateOption';

export {
    getUsers,
    isUsersFetching,
    getCurrentUser,
    getCurrentUserFetching,
    getCurrentUserError,
} from './users';
export {
    getEditUserId, isUserEditing, isCreateNewUser, isPostingUserChanges,
} from './userOnEdit';
export { isUserOnUpdate } from './userUpdate';
export { isUsernameCheckInProgress, isUsernameIsFreeToUse } from './username';
export { isShowDeactivated } from './deactivateOption';

const initialUser = { attributes: { firstName: '', lastName: '' } };

export const getUserToEdit = state => {
    const editUserId = getEditUserId(state);

    return editUserId
        ? getUsers(state).find(user => (user.username === editUserId))
        : initialUser;
};

export default {
    users,
    userOnEdit,
    userUpdate,
    username,
    deactivateOption,
};
