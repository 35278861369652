import React from 'react';
import Footer from '@src/components/Footer';
import BlockedAccountNavigation from '@src/containers/header/BlockedAccountNavigation';

const BlockedAccountLayout = ({ children }) => (
    <div id="mainContainer">
        <header id="header">
            <BlockedAccountNavigation />
        </header>
        <main className="container overlayedZone" role="main" id="main">
            {children}
        </main>
        <footer>
            <Footer />
        </footer>
    </div>
);

export default BlockedAccountLayout;
