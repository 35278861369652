import React from 'react';
import Loader from '@src/components/Loader';
import { useAuth } from './AuthContext';

const AuthBoundaries = ({
    children,
    authPage,
}) => {
    const { user, authStatus } = useAuth();

    if (authStatus === 'configuring') {
        return (
            <Loader show />
        );
    }

    if (!user && authStatus !== 'authenticated') {
        return authPage;
    }

    return children;
};

export default AuthBoundaries;
