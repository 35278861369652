import React from 'react';
import { Provider } from 'react-redux';
import store from '@src/store/store';
import DatadogConfig from '@src/containers/datadog/DatadogConfig';
import AlertsContent from '@src/containers/alert/AlertsContent';
import Auth from '@src/containers/auth';
import CombiningFilesBarContent from '@src/containers/combiningFilesBar/CombiningFilesBarContent';
import MainRoutes from '@src/router/mainRoutes';
import '@src/style/main.less';

const App = () => (
    <Provider store={store}>
        <DatadogConfig />

        <Auth>
            <AlertsContent />
            <CombiningFilesBarContent />
            <MainRoutes />
        </Auth>
    </Provider>
);

export default App;
