import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import {
    FAQS,
    CONTACT_INFO,
    ROOT,
} from '@src/utils/constants/routerConstants';
import { useAuth } from '@src/containers/auth';

const UnauthorizedMenu = ({
    mode = 'horizontal',
}) => {
    const { pathname } = useLocation();
    const { toSignIn, route } = useAuth();

    return (
        <Menu
            mode={mode}
            selectedKeys={[route === 'signIn' ? pathname : undefined]}
        >
            <MenuItem key={ROOT}>
                <NavLink
                    to={ROOT}
                    onClick={toSignIn}
                >
                    Sign in
                </NavLink>
            </MenuItem>
            <SubMenu key="SubMenu3" title="Help">
                <MenuItem key="setting10">
                    <a href={FAQS} target="blank">FAQ's</a>
                </MenuItem>
                <MenuItem key="setting11">
                    <a href={CONTACT_INFO} target="blank">Contact Us</a>
                </MenuItem>
            </SubMenu>
        </Menu>
    );
};

export default UnauthorizedMenu;
