import { request, postRequestConfig, getRequestConfig } from '@src/utils/apiServices/api';
import {
    urlBaseLogin,
    urlBaseLogout,
    urlBaseSetFirstPassword,
    urlBasePostVerificationCode,
    urlBaseResetPassword,
    urlBaseProfile,
} from '@src/utils/constants/endpointConstants';

export const getProfile = () => (
    request({
        ...getRequestConfig,
        url: `${urlBaseProfile}/sessionCheck`,
    })
);

export const login = ({ username, ...data }) => (
    request({
        ...postRequestConfig,
        url: urlBaseLogin,
        data: {
            ...data,
            username: username.trim(),
        },
    })
);

export const logout = data => (
    request({
        ...postRequestConfig,
        url: urlBaseLogout,
        data,
    })
);

export const setFirstPassword = data => (
    request({
        ...postRequestConfig,
        url: urlBaseSetFirstPassword,
        data,
    })
);

export const resetPassword = data => (
    request({
        ...postRequestConfig,
        url: urlBaseResetPassword,
        data,
    })
);

export const postVerificationCode = data => (
    request({
        ...postRequestConfig,
        url: urlBasePostVerificationCode,
        data,
    })
);
