import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import LogoImage from '@src/images/statecapital-logo.png';
import YearImage from '@src/images/year_95.png';
import { STATIC_SITE_URL } from '@src/utils/constants/routerConstants';
import UserMenu from '@src/components/UserMenu';
import SCTInfoMenu from '@src/components/SCTInfoMenu';
import { useAuth } from '@src/containers/auth';

const BlockedAccountNavigation = () => {
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
    const { signOut } = useAuth();

    return (
        <nav role="navigation" className="navbar bottom-buffer-no">
            <div className="container container-header">
                <div className="site-branding" id="logo">
                    <a href={STATIC_SITE_URL} id="indexPageLink">
                        <img
                            border="0"
                            alt="State Capital Title &amp; Abstract Co."
                            src={LogoImage}
                        />
                        <img
                            border="0"
                            alt="State Capital Title &amp; Abstract Co."
                            src={YearImage}
                        />
                    </a>
                </div>
                <div
                    id="mainNavbar"
                    className="main-nav"
                >
                    <SCTInfoMenu />
                    <Button
                        type="text"
                        className="main-nav-mobile-toggle-btn"
                        icon={isSideMenuVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                        onClick={() => setIsSideMenuVisible(prevState => !prevState)}
                    />
                    <Drawer
                        width={250}
                        placement="right"
                        closable={false}
                        onClose={() => setIsSideMenuVisible(false)}
                        open={isSideMenuVisible}
                    >
                        <SCTInfoMenu mode="inline" />
                    </Drawer>
                </div>
                <div id="header-mini-nav">
                    <UserMenu
                        onLogOut={signOut}
                        isInfoVisible={false}
                        isSettingsVisible={false}
                    />
                </div>
            </div>
        </nav>
    );
};

export default BlockedAccountNavigation;
