import {
    ACCOUNT_SETTINGS_PERSONAL,
    ORDER_SERVICE_NAME_SEARCH,
    ORDER_SERVICE_ABSTRACT_SEARCH,
    ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS,
    ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS,
    ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS,
} from '@src/utils/constants/routerConstants';

const initialDeliverySettings = {
    attachmentName: '',
    emailConf: false,
    emailConfAddress: '',
    emailResults: false,
    emailSubject: '',
};

//Uses for initial form in ProfileSettingsContent
export const initialProfileSettings = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    ext: '',
    defaultPage: '',
    copySelectedServices: false,
    outputControlledBy: '',
    companyDeliverySettings: initialDeliverySettings,
    individualDeliverySettings: initialDeliverySettings,
};

export const initialReportSettings = {
    outputControlledBy: '',
    referenceRequired: true,
    webDelivery: true,
    companyDeliverySettings: initialDeliverySettings,
    individualDeliverySettings: initialDeliverySettings,
};

export const initialReportDeliverySettings = {
    confirmationEmail: null,
    confirmationEmailList: [],
    contactName: '',
    contactId: null,
    contactList: [],
    lastCertificationDate: '',
    beginCertificationDate: '',
    copySelectedServices: false,
    referenceRequired: false,
    deliveryPreference: {
        accutitle: false,
        additionalEmail: '',
        email: '',
        emailResults: false,
        rushDate: null,
        rushOrder: false,
        webDelivery: false,
        availableEmails: [],
        canChangeWebDelivery: false,
        canMakeAccutitle: false,
        emailChecked: false,
    },
};

const initialContact = {
    addr1: '',
    addr2: '',
    city: '',
    phone: '',
    state: '',
    zip: '',
};

export const initialBusinessContact = {
    billingSettings: { ...initialContact },
    mailingSettings: { ...initialContact },
};

export const initialIndividualSearches = {
    docketIndex: '',
    nameIndex: '',
    outputFormat: '',
    outputSequence: '',
};

export const initialPropertySearches = {
    floodSearchSupplier: '',
    wetlandsSearchSupplier: '',
    taxSearchSupplier: '',
    tideAddlCharge: false,
    tideNeedGrants: false,
    tidelandSearchSupplier: '',
};

export const initialPasswordSettings = {
    oldPassword: '',
    password: '',
    repeatPassword: '',
};

export const NAME_SEARCH = 'SRCH';
export const CHANCERY_ABSTRACT = 'CHNC';
export const NEW_ORDERS = 'NEW';
export const COMPLETED_REPORTS = 'COMP';
export const OPEN_REPORTS = 'OPEN';
export const PERSONAL_SETTINGS = 'PERSONAL';

// Uses in ProfileSettingsContent for render options Default login page
export const getOptionsDefaultLoginPage = () => ([
    {
        label: 'Order Services',
        options: [
            { value: NAME_SEARCH, label: 'Searches', disabled: false },
            { value: CHANCERY_ABSTRACT, label: 'Chancery Abstract', disabled: false },
        ],
    },
    {
        label: 'View My Orders',
        options: [
            { value: NEW_ORDERS, label: 'New Orders', disabled: false },
            { value: COMPLETED_REPORTS, label: 'Completed Reports', disabled: false },
        ],
    },
]);

export const defaultPageUrls = {
    [NAME_SEARCH]: ORDER_SERVICE_NAME_SEARCH,
    [CHANCERY_ABSTRACT]: ORDER_SERVICE_ABSTRACT_SEARCH,
    [NEW_ORDERS]: ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS,
    [COMPLETED_REPORTS]: ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS,
    [OPEN_REPORTS]: ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS,
    [PERSONAL_SETTINGS]: ACCOUNT_SETTINGS_PERSONAL,
};
