import React from 'react';
import { createMemoryRouter, Outlet, RouterProvider } from 'react-router-dom';
import UnauthorizedLayout from '@src/layouts/UnauthorizedLayout';
import { REGISTRATION, ROOT } from '@src/utils/constants/routerConstants';
import Registration from '@src/containers/registration/components/Registration';
import AuthMainPage from './AuthMainPage';

const router = createMemoryRouter(
    [{
        element: (
            <UnauthorizedLayout>
                <Outlet />
            </UnauthorizedLayout>
        ),
        children: [
            {
                path: ROOT,
                element: <AuthMainPage />,
            },
            {
                path: REGISTRATION,
                element: <Registration />,
            },
        ],
    }],
    { initialEntries: ['/'] },
);

const AuthRouter = () => (
    <RouterProvider router={router} />
);

export default AuthRouter;
