import { setCookie, getCookie, deleteAllCookies } from '@src/utils/services/cookies';

export default class CustomTokenStorage {
    customKeys = {
        accessToken: 'token',
    }

    getCustomKey(key = '') {
        const trimedKey = key.split('.').at(-1);
        return this.customKeys[trimedKey];
    }

    setItem(key = '', value) {
        if (this.getCustomKey(key)) {
            setCookie(this.getCustomKey(key), value);
        }
    }

    getItem(key = '') {
        return getCookie(this.getCustomKey(key));
    }

    removeItem(key = '') {
        setCookie(this.getCustomKey(key), null, -1);
    }

    // eslint-disable-next-line class-methods-use-this
    clear() {
        deleteAllCookies();
    }
}
