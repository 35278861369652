import React from 'react';
import { Button } from 'antd';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import {
    composeValidators, equalTo, minLength, required,
} from '@src/utils/helpers/validationRules';
import { leadingWhitespacesNormalizer } from '@src/utils/helpers/normalize';
import { useAuth } from './AuthContext';

const focusOnError = createDecorator();

const AuthConfirmResetPasswordForm = () => {
    const { submitForm, isPending, username } = useAuth();

    const onSubmitHandler = values => {
        const confirmationCode = String(values.confirmationCode).trim().replaceAll(' ', '');

        return submitForm({
            confirmation_code: confirmationCode,
            password: values.password,
            username: values.username,
        });
    };

    return (
        <Form
            onSubmit={onSubmitHandler}
            decorators={[focusOnError]}
            validate={({ repeatPassword, password }) => ({
                repeatPassword: equalTo(password, 'Enter Password')(repeatPassword),
            })}
        >
            {
                params => {
                    const {
                        handleSubmit,
                        values,
                    } = params;

                    return (
                        <form onSubmit={handleSubmit} id="reset-password-form">
                            <Field
                                name="username"
                                component={InputGroupHiddenFieldAdapter}
                                controlId="username"
                                defaultValue={username}
                            />
                            <Field
                                name="confirmationCode"
                                component={InputGroupAntInputAdapter}
                                controlId="confirmationCode"
                                labelText="Verification Code"
                                isRequired
                                validate={composeValidators(
                                    required,
                                )}
                                parse={leadingWhitespacesNormalizer}
                            />
                            <Field
                                name="password"
                                component={InputGroupAntInputAdapter}
                                controlId="password"
                                labelText="New Password"
                                isRequired
                                validate={composeValidators(
                                    required,
                                    minLength(8),
                                )}
                                type="password"
                                parse={leadingWhitespacesNormalizer}
                            />
                            <Field
                                name="repeatPassword"
                                component={InputGroupAntInputAdapter}
                                controlId="repeatPassword"
                                labelText="Repeat Password"
                                isRequired
                                validate={composeValidators(
                                    required,
                                    minLength(8),
                                    equalTo(values.password, 'Enter Password'),
                                )}
                                type="password"
                                parse={leadingWhitespacesNormalizer}
                            />
                            <Button
                                className="right-buffer-xs"
                                htmlType="submit"
                                type="primary"
                                loading={isPending}
                                block
                            >
                                Submit
                            </Button>
                        </form>
                    );
                }
            }
        </Form>
    );
};

export default AuthConfirmResetPasswordForm;
