import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Authenticator,
    Button,
    View,
    useTheme,
    ThemeProvider,
} from '@aws-amplify/ui-react';
import {
    signIn,
    resetPassword,
    confirmResetPassword,
} from '@aws-amplify/auth';
import { REGISTRATION } from '@src/utils/constants/routerConstants';
import { emailPattern } from '@src/utils/helpers/regexps';
import AuthCustomFormsPortal from './AuthCustomFormsPortal';
import { useAuth } from './AuthContext';

const theme = {
    name: 'ant-theme',
    tokens: {
        radii: {
            small: '2px',
        },
        colors: {
            primary: {
                '10': '#fff',
                '20': '#fff',
                '60': '#586273',
                '80': '#3e4b65',
                '90': '#586273',
                '100': '#586273',
            },
            font: { hover: '#3e4b65' },
        },
        components: {
            fieldcontrol: {
                paddingBlockEnd: '4px',
                paddingBlockStart: '4px',
                paddingInlineEnd: '11px',
                paddingInlineStart: '11px',
                lineHeight: '1.5715',
                _focus: {
                    borderColor: '#c0c4cc',
                    boxShadow: '0 0 3px 3px rgba(151, 164, 191, 0.2)',
                },
            },
            field: {
                flexDirection: 'row',
                gap: '0',
                fontSize: '14px',
            },
            button: {
                fontWeight: '400',
                lineHeight: '1.5715',
                addingBlockEnd: '5px',
                paddingBlockStart: '5px',
                paddingInlineEnd: '11px',
                paddingInlineStart: '11px',
            },
            authenticator: {
                form: {
                    padding: '0rem 2rem 1rem',
                },
            },
        },
    },
};

const services = {
    handleSignIn: async values => {
        const username = String(values.username).toLowerCase().trim();
        const password = String(values.password).trim();

        if (username && !emailPattern.test(username)) {
            throw new Error('Username must be a valid email address');
        }

        return (
            signIn({
                ...values,
                password,
                username,
            })
        );
    },
    handleForgotPassword: async values => {
        const username = String(values.username).toLowerCase().trim();

        if (username && !emailPattern.test(username)) {
            throw new Error('Username must be a valid email address');
        }

        return resetPassword({
            ...values,
            username,
        });
    },
    handleForgotPasswordSubmit: async values => {
        const confirmationCode = String(values.confirmationCode).trim().replaceAll(' ', '');

        return confirmResetPassword({
            ...values,
            confirmationCode,
        });
    },
};

const pageTitle = {
    signIn: 'Sign in',
    forgotPassword: 'Forgot Password',
    confirmResetPassword: 'Forgot Password',
};

const AuthMainPage = () => {
    const navigate = useNavigate();
    const { route } = useAuth();
    const { tokens } = useTheme();

    const components = useMemo(() => ({
        SignIn: {
            Footer: () => (
                <>
                    <View
                        id="sign-in-form--portal"
                        paddingLeft="2rem"
                        paddingRight="2rem"
                        paddingBottom="1rem"
                    />
                    <View
                        textAlign="center"
                        paddingLeft="2rem"
                        paddingRight="2rem"
                        paddingBottom={tokens.space.medium}
                    >
                        <Button
                            isFullWidth
                            onClick={() => navigate(REGISTRATION)}
                        >
                            Register
                        </Button>
                    </View>
                    <Authenticator.SignIn.Footer />
                </>
            ),
            Header: () => (
                <View
                    paddingTop="2rem"
                    paddingLeft="2rem"
                    paddingRight="2rem"
                >
                    <h3>Sign in using your credentials</h3>
                </View>
            ),
        },
        ForgotPassword: {
            Header: () => (
                <View paddingTop="2rem" />
            ),
        },
        ConfirmResetPassword: {
            Header: () => (
                <View
                    paddingTop="2rem"
                >
                    <div className="infoCard">
                        <span>Verification code has been sent to your email account.</span>
                        <br />
                        <br />
                        <span>Please follow next instructions to set up new password :</span>
                        <div id="passwordRequirements">
                            <ul className="list-style-circle">
                                <li><span>length should be at least 8 characters</span></li>
                                <li><span>at least one character in upper case</span></li>
                                <li><span>at least one number</span></li>
                            </ul>
                        </div>
                    </div>
                </View>
            ),
        },
    }), []);

    return (
        <ThemeProvider theme={theme}>
            <AuthCustomFormsPortal />
            <div className="contentHeader text-center"><h1>{pageTitle[route]}</h1></div>
            <Authenticator
                hideSignUp
                services={services}
                components={components}
            />
        </ThemeProvider>
    );
};

export default AuthMainPage;
