import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SelectCustomer from '@src/components/bages/SelectCustomer';
import CurrentCustomer from '@src/components/bages/CurrentCustomer';
import AffiliatedCompany from '@src/components/bages/AffiliatedCompany';
import Navigation from '@src/components/bages/Navigation';
import { getCurrentUser, getIsAuthUser } from '@src/store/reducers/userMaintanance/users';
import {
    getAffiliatedCustomers,
    getFetchingAffiliatedCustomers,
    getCurrentCustomer,
    getSelectingAffiliatedCustomer,
} from '@src/store/reducers/customers';
import {
    selectAffiliatedCustomer,
    resetCustomer,
} from '@src/store/actions/customers';
import { toCommaSeparatedString } from '@src/utils/helpers/normalize';
import { ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS } from '@src/utils/constants/routerConstants';
import { useAuth } from '@src/containers/auth';

const Header = () => {
    const navigate = useNavigate();
    const redirect = () => navigate(ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS);
    const { signOut } = useAuth();

    const dispatch = useDispatch();
    const {
        firstName,
        lastName,
        canSelectAffiliatedCompany,
        canSelectCustomer,
        superAdmin,
        customerAddressLine1: addressLine1,
        customerAddressLine2: addressLine2,
        customerCity: city,
        phone,
        customerPostalCode: postalCode,
        customerRegion: region,
        customerNumber: userId,
    } = useSelector(getCurrentUser);
    const {
        name: customerName,
        number: customerNumber,
        nickName,
        allowPropertySearch,
        allowWebOrders,
        creditHold,
        defaultCustomer,
        webDelivery,
        addressLine1: customerAddressLine1,
        addressLine2: customerAddressLine2,
        city: customerCity,
        phone: customerPhone,
        postalCode: customerPostalCode,
        region: customerRegion,
    } = useSelector(getCurrentCustomer);
    const isAuthUser = useSelector(getIsAuthUser);
    const affiliatedCustomers = useSelector(getAffiliatedCustomers);
    const isFetchingAffiliatedCustomers = useSelector(getFetchingAffiliatedCustomers);
    const isSelectingAffiliatedCustomer = useSelector(getSelectingAffiliatedCustomer);

    const userAddress = toCommaSeparatedString([
        addressLine1,
        addressLine2,
        city,
        region,
        postalCode,
    ]);
    const currentCustomerAddress = toCommaSeparatedString([
        customerAddressLine1,
        customerAddressLine2,
        customerCity,
        customerRegion,
        customerPostalCode,
    ]);

    return (
        <>
            <Navigation
                onLogOut={signOut}
                accountNumber={userId}
                address={userAddress}
                phone={phone}
                userName={`${firstName} ${lastName}`}
                isAuthorized={isAuthUser}
                allowPropertySearch={allowPropertySearch || superAdmin}
                allowWebOrders={!creditHold && (allowWebOrders || superAdmin)}
                canSelectCustomer={canSelectCustomer}
                isWebDelivery={webDelivery}
            />
            {
                isAuthUser
                && (
                    <>
                        {
                            canSelectCustomer && customerNumber
                            && (
                                <SelectCustomer
                                    isDefaultCustomer={defaultCustomer}
                                    isCreditHold={creditHold}
                                    customerName={customerName}
                                    nickName={nickName}
                                    customerNumber={customerNumber}
                                    address={currentCustomerAddress}
                                    phone={customerPhone}
                                    onReset={() => dispatch(resetCustomer(redirect))}
                                />
                            )
                        }
                        {
                            canSelectAffiliatedCompany && !!affiliatedCustomers.length
                            && (
                                <AffiliatedCompany
                                    customers={affiliatedCustomers}
                                    fetching={isFetchingAffiliatedCustomers || isSelectingAffiliatedCustomer}
                                    currentCustomerId={customerNumber}
                                    onSelectCustomer={id => dispatch(selectAffiliatedCustomer(id))}
                                    isSctUser={canSelectCustomer}
                                />
                            )
                        }
                        {
                            !canSelectCustomer && !canSelectAffiliatedCompany
                            && (
                                <CurrentCustomer
                                    customerName={customerName}
                                    nickName={nickName}
                                />
                            )
                        }
                    </>
                )
            }
        </>
    );
};

export default Header;
